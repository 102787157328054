<template>
  <div class="h-100">
    <div class="login-cover">
      <div class="login-cover-image" v-bind:style="{ backgroundImage: 'url('+ bg.activeImg +')' }"></div>
      <div class="login-cover-bg"></div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'appLayoutBlank',
  data () {
    return {
      bg: {
        activeImg: '/assets/img/login-bg/login-bg-17.jpg'
      }
    }
  },
  mounted () {
    this.$insProgress.finish()
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)

    this.$insProgress.start()

    this.$router.beforeEach((to, from, next) => {
      this.$insProgress.start()
      next()
    })
    this.$router.afterEach(() => {
      this.$insProgress.finish()
    })
  }
}
</script>
